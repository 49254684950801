import axios from 'axios';
import { fetchFromStorage } from '../context/storage';
import siteConstant from '../constant/siteConstant';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiInstance.interceptors.request.use((config) => {
  const token = fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA)?.token;
  const clonedConfig = config;
  if (token) {
    clonedConfig.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
  } else {
    clonedConfig.headers = {
      "Content-Type": "multipart/form-data",
    };
  }
  return clonedConfig;
})

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 400) {
      // Handle 400 errors here
      console.error('Error:', error.response.data.message);
    }
    return Promise.reject(error.response.data ? error.response.data : error);
  }
);

export default apiInstance;