import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import postimg from "src/assets/images/postimg1.svg";
import pofileicon from "src/assets/images/recentimg.png";
import { InputAdornment, Dialog } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { FormGroup, FormControlLabel, Stack, Typography } from "@mui/material";
import Android12Switch from "src/views/components/UploadPost/socialswitch";
import moment from 'moment'
import { CustomTextField } from "src/views/components/custom/CustomTextField";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'; // Import the keyboard arrow forward icon
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { URL as API_URL } from "src/helpers/constant/Url";
import { setApiMessage } from "src/helpers/context/toaster";
import apiInstance from "src/helpers/Axios/axiosINstance";
import { fetchFromStorage } from "src/helpers/context/storage";
import siteConstant from "src/helpers/constant/siteConstant";

// function highlightHashtagsAndMentions(text) {
//   const regex = /([#@][\w-]+)/g;
//   const parts = text.split(regex);

//   return (
//     parts?.map((part, index) => {
//       if (part.match(regex)) {
//         return (<span key={index} style={{ color: part.startsWith('#') || part.startsWith('@') ? 'blue' : 'green' }}>
//           {part}
//         </span>)
//       }
//       return part;
//     })
//   )
// }


const UploadPost = ({ open, onClose }) => {
  const fileInputRef = useRef(null);
  const [uploaded, setUploaded] = useState(false);
  const [images, setImages] = useState([]);
  const [uploadedFile, setuploadedFile] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selected, setSelected] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [shareChecked, setshareChecked] = useState(false);
  const socialMediaConnected = fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA)
  const [startDate, setStartDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateChange = (date) => {
    setStartDate(date);
    setShowDatePicker(false);
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
      setUploaded(false);
    }
  };

  const handleFileInputClick = () => {
    if (!selected) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      console.log(files);
      const uploadedImages = Array.from(files).map((file) => {
        return {
          url: URL.createObjectURL(file),
          is_video: getFileType(file?.name) == "video" ? true : false
        }
      }
      );
      setImages(uploadedImages);
      setCurrentImageIndex(0);
      setUploaded(true);
      setSelected(true);
      setuploadedFile(files);
    }
  };

  const handleNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevSlide = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const CustomNextArrow = ({ onClick }) => (
    <div className="absolute top-1/2 right-[-23px] transform -translate-y-1/2 z-10 cursor-pointer bg-red-50 p-2 rounded-full">
      <KeyboardArrowRightIcon
        className="text-red-500 hover:text-red-700"
        onClick={onClick}
      />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div className="absolute top-1/2 left-[-19px] transform -translate-y-1/2 z-10 cursor-pointer bg-red-50 p-2 rounded-full">
      <KeyboardArrowRightIcon
        className="rotate-180 text-red-500 hover:text-red-700 "
        onClick={onClick}
      />
    </div>
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow onClick={handleNextSlide} />,
    prevArrow: <CustomPrevArrow onClick={handlePrevSlide} />,
  };

  const handleDialogClose = () => {
    onClose();
    setUploaded(false);
    setImages([]);
    setCurrentImageIndex(0);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      console.log(files);
      const uploadedImages = Array.from(files).map((file) => {
        return {
          url: URL.createObjectURL(file),
          is_video: getFileType(file?.name) == "video" ? true : false
        }
      }
      );
      setImages(uploadedImages);
      setCurrentImageIndex(0);
      setUploaded(true);
      setSelected(true);
      setuploadedFile(files)
    }
  };

  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    const connectedPlatforms = Object.keys(socialMediaConnected).filter(key => socialMediaConnected[key] === true);
    const updatedPlatforms = siteConstant?.CHANNEL_LIST.filter((platform) => {
      if (connectedPlatforms.includes(platform?.name)) {
        return { ...platform };
      }
    }).map((platform) => {
      setshareChecked(true)
      return { ...platform, selected: true }
    })
    console.log(updatedPlatforms)
    setPlatforms(updatedPlatforms);
  }, []);

  const handleShareAll = (checked) => {
    let updatedPlatforms = platforms.map((platform) => {
      return { ...platform, selected: checked };
    });
    setshareChecked(checked)

    setPlatforms(updatedPlatforms);
  };

  const handleToggle = (id) => {
    console.log(id)
    let updatedPlatforms = platforms.map((platform) =>
      platform?.id === id ? { ...platform, selected: !platform?.selected } : platform
    );
    setshareChecked(!updatedPlatforms.some((platform) => !platform?.selected))
    setPlatforms(updatedPlatforms);
  };

  const getFileType = (src) => {
    const extension = src.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'ico', 'heif', 'heic', 'raw', 'nef', 'cr2', 'orf', 'arw', 'dng'];
    const videoExtensions = ['mp4', 'mkv', 'mov', 'avi', 'flv', 'wmv', 'webm', 'm4v', 'mpg', 'mpeg', '3gp', '3g2', 'mts', 'm2ts', 'ts', 'ogv', 'rm', 'rmvb'];

    if (imageExtensions.includes(extension)) {
      return 'image';
    } else if (videoExtensions.includes(extension)) {
      return 'video';
    } else {
      return 'unknown';
    }
  };

  return (
    <>
      <Dialog
        open={open && !uploaded}
        onClose={handleDialogClose}
        className="fixed inset-0 z-10 flex items-center justify-center"
      >
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={handleBackdropClick}
        ></div>
        <div
          className="relative bg-white rounded-3xl overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-8 "
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`border border-5 border-gray-400 border-dashed rounded-xl p-6 cursor-pointer font-Montserat ${isDragging && "border-Red border-2"
              }`}
          >
            <div
              className="bg-white px-4 pt-5 pb-2 sm:p-6 sm:pb-4"
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleFileInputClick}
              ref={fileInputRef}
            >
              <div className="sm:flex sm:items-center sm:justify-center">
                <div className="">
                  <p className="sm:text-3xl font-bold text-center pb-5 text-gray-900">
                    Create New Post
                  </p>
                  <div className="mt-2 flex flex-col justify-center items-center">
                    <img src={postimg} alt="Post" className="w-24 h-24" />
                    <p className="text-sm mt-4 font-semibold">
                      Drag photos and videos here
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="px-4 py-3 sm:px-4 flex justify-center"
            // onClick={handleFileInputClick}
            >
              <label
                htmlFor="upload-input"
                className="bg-Red px-4 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer"
              >
                Select your Photos
              </label>
              <input
                id="upload-input"
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />
            </div>
          </div>
        </div>
      </Dialog>

      {uploaded && (
        <Dialog
          open={uploaded}
          onClose={handleDialogClose}
          className="fixed inset-0 z-10 sm:flex sm:items-center sm:justify-center  "
        >
          <div
            className=" px-10 fixed inset-0  flex justify-center items-center bg-gray-900 bg-opacity-50 overflow-auto"
            aria-hidden="true"
            onClick={handleBackdropClick}
          >

            <Formik
              initialValues={{
                description: "",
                location: "",
                audience: false,
                schedule: moment().local(),
                facebook: false,
                instagram: false,
                linkedin: false,
                pinterest: false,
                vimeo: false,
                youtube: false,
                dailymotion: false,
                twitter: false,
                tagged_in: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  console.log(values);
                  const form = new FormData()
                  form.append('title', 'My Title')
                  form.append('description', values?.description)
                  form.append('location', values?.location)
                  form.append('is_private', values?.audience)
                  platforms?.filter(platform => platform.name == 'Facebook')?.[0]?.selected && form.append('facebook', 'True');
                  platforms?.filter(platform => platform.name == 'Instagram')?.[0]?.selected && form.append('instagram', 'True');
                  platforms?.filter(platform => platform.name == 'Linkedin')?.[0]?.selected && form.append('linkedin', 'True');
                  platforms?.filter(platform => platform.name == 'Pinterest')?.[0]?.selected && form.append('pinterest', 'True');
                  platforms?.filter(platform => platform.name == 'Vimeo')?.[0]?.selected && form.append('vimeo', 'True');
                  platforms?.filter(platform => platform.name == 'YouTube')?.[0]?.selected && form.append('youtube', 'True');
                  platforms?.filter(platform => platform.name == 'Dailymotion')?.[0]?.selected && form.append('dailymotion', 'True');
                  platforms?.filter(platform => platform.name == 'Twitter')?.[0]?.selected && form.append('twitter', 'True');
                  values?.tagged_in && form.append('tagged_in', values?.tagged_in);
                  // values?.schedule ?? form.append('scheduled_at', values?.schedule);
                  for (let file of uploadedFile) {
                    form.append('upload_files', file)
                  }

                  console.log('form', form)
                  const { status, data } = await apiInstance.post(API_URL.UPLOAD_POST, form)
                  if (data) {
                    if (data?.status) {
                      setApiMessage('success', data?.message)
                    } else {
                      setApiMessage('error', data?.message)
                    }
                  } else {
                    setApiMessage('error', "login failed")
                  }
                  setSubmitting(false);
                } catch (error) {
                  console.log("error", error);
                  setApiMessage('error', error?.message)
                }
                console.log("createpost", values)

                setTimeout(() => {
                  setSubmitting(false);
                  handleDialogClose();
                }, 400);
              }}
            >
              {({ errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                isSubmitting, }) => (
                <Form onSubmit={handleSubmit} className="space-y-2.5 font-Montserrat">
                  <div className={`w-xl sm:max-w-2xl ${images.length === 1 ? 'lg:max-w-3xl' : 'lg:max-w-[770px]'} w-full md:flex md:flex-col md:space-x-6 sm:mt-40 bg-white shadow-lg rounded-[30px] overflow-hidden`}>
                    <div className="relative">
                      <p className="text-center text-xl md:text-2xl font-bold text-gray-800 mb-4 bg-gray-100 py-4 border-b">
                        Create New Post
                      </p>
                      <div className="absolute top-5 right-6 font-medium font-Montserat text-[15px]">
                        <button
                          variant="contained"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col h-[500px] md:flex md:flex-row items-center md:space-x-6">
                      <div className="w-[220px] justify-center text-center md:w-1/2 mb-4 md:mt-4 pe-5 relative">
                        {images.length === 1 ? (
                          images[0]?.is_video ?
                            <video
                              src={images[0]?.url}
                              controls
                              className="md:w-full w-[250px] md:h-full h-[250px] inline-block rounded-2xl"
                            >
                              Your browser does not support the video tag.
                            </video> : <img
                              src={images[0]?.url}
                              alt={`Image 1`}
                              className="md:w-full w-[250px] md:h-full h-[250px] inline-block rounded-[30px]"
                            />
                        ) : (
                          <Slider {...settings}>
                            {images.map((item, index) => {
                              return (
                                <div key={index} className="h-auto justify-center  text-center">
                                  {item?.is_video ? (
                                    <video
                                      src={item?.url}
                                      controls
                                      className="md:w-full w-[200px] md:h-full h-[200px] inline-block rounded-2xl"
                                    >
                                      Your browser does not support the video tag.
                                    </video>
                                  ) : (
                                    <img
                                      src={item?.url}
                                      alt={`Media ${index + 1}`}
                                      className="md:w-full w-[200px] md:h-full h-[200px] inline-block rounded-2xl"
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </Slider>
                        )}
                      </div>
                      <div className="w-full md:w-1/2 grid grid-cols-1 gap-4 px-5 md:px-0 md:pe-4 overflow-auto md:h-[500px]">
                        <div className="flex gap-4">
                          <div className="">
                            <img
                              src={pofileicon}
                              alt="profile-icon"
                              className="w-8 h-8 rounded-3xl border-2 border-black"
                            />
                          </div>
                          <div>
                            <p className="text-sm font-bold font-Montserat mt-1">
                              Anton Demeron
                            </p>
                          </div>
                        </div>

                        <div>
                          <CustomTextField
                            variant="standard"
                            fullWidth
                            // borderColor={'none'}
                            className="w-full pb-2 resize-none font-Montserat text-sm"
                            placeholder="Write Caption..."
                            multiline
                            rows={4}
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {/* <hr /> */}
                        </div>
                        <div className=" border rounded-lg pt-0.5 shadow-sm shadow-red-200">
                          <CustomTextField variant="outlined"
                            className="outline-none focus:ring-none"
                            fullWidth
                            borderColor={'none'}
                            name={'location'}
                            placeholder={'Enter location'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.location}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={siteConstant?.SOCIAL_ICONS?.LOCATION_ICON} style={{ height: '25px' }}></img>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <KeyboardArrowRightIcon className="text-arrowIconColor" />
                                </InputAdornment>
                              ),
                            }} />
                        </div>
                        <div className=" border rounded-lg pt-0.5 shadow-sm shadow-red-200">
                          <CustomTextField
                            variant="outlined"
                            className="outline-none text-black focus:ring-none"
                            fullWidth
                            name={'tagged_in'}
                            placeholder={'Enter people to tag'}
                            borderColor={'none'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tagged_in}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={siteConstant?.SOCIAL_ICONS?.PERSON_ICON} style={{ height: '25px' }}></img>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <KeyboardArrowRightIcon className="text-arrowIconColor" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="relative border pt-0.5 rounded-lg shadow-sm shadow-red-200">
                          <CustomTextField
                            variant="outlined"
                            className="outline-none focus:ring-none"
                            fullWidth
                            name="audience"
                            placeholder="Audience"
                            value={values.audience ? 'Private' : 'Public'}
                            borderColor={'none'}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={siteConstant?.SOCIAL_ICONS?.AUDIANCE_ICON} style={{ height: '25px' }} alt="Audience" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Android12Switch checked={values.audience} onClick={(e) => setFieldValue('audience', e.target.checked)} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="relative border rounded-lg pt-1 shadow-sm shadow-red-200">
                          <CustomTextField
                            variant="outlined"
                            className="outline-none focus:ring-none"
                            fullWidth
                            name="schedule"
                            placeholder="Schedule Post"
                            borderColor={'none'}
                            value={startDate ? startDate.toLocaleString() : startDate}
                            onClick={() => setShowDatePicker(!showDatePicker)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={siteConstant?.SOCIAL_ICONS?.CALENDER_ICON} style={{ height: '25px' }} alt="Calender" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <KeyboardArrowRightIcon className="text-arrowIconColor" />
                                </InputAdornment>
                              ),
                            }}
                          />

                        </div>
                        {showDatePicker && (
                          <div className="border-none rounded-lg shadow-lg bg-white">
                            <DatePicker
                              selected={startDate}
                              onChange={handleDateChange}
                              onBlur={() => setShowDatePicker(false)}
                              showTimeSelect
                              dateFormat="Pp"
                              inline
                            />
                          </div>
                        )}
                        {platforms.length ? (<div className="flex justify-between items-center font-Montserrat">
                          <Typography variant="subtitle1">Share With</Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={<Android12Switch checked={shareChecked} onClick={(e) => handleShareAll(e.target.checked)} />}
                              label=""
                            />
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            ></Stack>
                          </FormGroup>
                        </div>) : <></>}

                        <div>
                          {platforms.map((platform) => (
                            <div
                              key={platform?.id}
                              className="flex justify-between items-center font-Montserrat mb-4 w-full"
                            >
                              <div className="flex items-center">
                                <div className="h-6 w-8 flex justify-center items-center">
                                  <img
                                    src={`${platform?.icon}`}
                                    style={{ color: platform?.iconColor, fontSize: '25px' }}
                                  />
                                </div>
                                <span className="font-semibold text-xs text-black sm:text-sm ml-5">
                                  {platform?.name}
                                </span>
                              </div>
                              <FormGroup className="flex ">
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      checked={platform?.selected}
                                      onChange={() => handleToggle(platform?.id)}
                                    />
                                  }
                                  label=""
                                />
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                ></Stack>
                              </FormGroup>
                            </div>
                          ))}
                        </div>


                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Dialog >
      )}
    </>
  );
};

export default UploadPost;
