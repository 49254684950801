import {
    Add,
    Feed as FeedIcon,
    Home,
} from '@mui/icons-material';
import { Avatar } from '@mui/material';

const sidebarProps = [{
    id: 1,
    name: "Hi, jack",
    link: "/profile",
    logo: <Avatar className="dark:text-gray-400" />
}, {
    id: 2,
    name: "Dahboard",
    link: "/dashboard",
    logo: <Home className="bg-white rounded-md p-1 text-Red dark:text-gray-400" />
}, {
    id: 3,
    name: "Upload Post",
    action: "uploadPost",
    logo: <Add className="bg-white rounded-md p-1 text-Red dark:text-gray-400" />
}]

export default sidebarProps