
const URL = {
    LOGIN: '/login/',
    FORGOT_PASSWORD: '/forgot-password/',
    OTP_VERIFY: "/verify-otp/",
    RESET_PASSWORD: "/reset-password/",
    USER_ADMIN_PROFILE: "/user-admin-profile/",
    UPLOAD_POST: "/upload-post/"
}


module.exports = { URL }