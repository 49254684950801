import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../../src/assets/images/logo.svg'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Link,
  CssBaseline,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Feed as FeedIcon,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../layouts.css';
import { clearStorage } from 'src/helpers/context/storage';
import sidebarProps from 'src/helpers/constant/sidebar';
import UploadPost from 'src/views/components/UploadPost';

const drawerWidth = 240;

const theme = createTheme({
  transitions: {
    create: (props, options) => {
      if (!Array.isArray(props)) {
        return '';
      }
      return props.map((prop) => `${prop} ${options.duration}ms ${options.easing} ${options.delay}ms`).join(',');
    },
    easing: {
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      enteringScreen: 225,
      leavingScreen: 195,
    },
    delay: 150,
  },
  spacing: (factor) => `${0.25 * factor}rem`,
});

const Sidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUploadPostOpen = () => {
    setIsUploadDialogOpen(true);
  };

  const handleUploadPostClose = () => {
    setIsUploadDialogOpen(false);
  };

  const logout = () => {
    clearStorage();
    navigate('/sign-in');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backdropFilter: 'blur(10px)',
            boxShadow: 'none',
            // backgroundColor: 'rgba(255, 255, 255, 0.7)',
            borderBottom: '1px solid rgb(220,220,220)',
          }}
        >
          <Toolbar className="flex justify-between px-3 font-Montserat lg:px-5 lg:pl-3  bg-primarBG">
            <Box className="flex items-center">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                sx={{ display: { lg: 'none' } }}
              >
                <MenuIcon className="text-gray-500 dark:text-gray-400" />
              </IconButton>
              <div href="https://yooii.com" className="flex">
                <img src={logo} alt='Yooii' className='h-12 w-12 md:w-[85px] md:h-[85px] md:ms-12  sm:py-0.5' />
              </div>
              <div className='sm:me-20 md:ms-44'>
                <form class="w-full">
                  <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                  <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                      </svg>
                    </div>
                    <input type="search" id="default-search" class="block md:w-80 sm:p-3 bg-white  ps-7 sm:ps-10 text-sm text-gray-900 border border-gray-300 rounded-full  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Products, Orders and Clients" required />
                  </div>
                </form>

              </div>
            </Box>

            <Box className="flex items-center pe-4">
              <p className='text-3xl md:text-5xl text-black font-Yooiilogo me-3 sm:pt-1'>yooii</p>
              <IconButton
                onClick={handleMenuOpen}
                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              >
                <Avatar
                  alt="User Photo"
                  src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                className="mt-1"
              >
                <MenuItem>
                  <Typography variant="body1">Neil Sims</Typography>
                  <Typography variant="body2" color="textSecondary">
                    neil.sims@flowbite.com
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
                <MenuItem onClick={logout}>Sign Out</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              marginTop: {
                xs: '57px',
                sm: "64px"
              },
              transition: theme.transitions.create(['margin-top'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
                delay: theme.transitions.delay,
              }),
            },
          }}
        >
          <Box className="h-full px-3 pb-4 font-Montserat bg-primarBG overflow-y-auto">
            <List>
              {sidebarProps?.map((sidebar) => (
                <ListItem
                  key={sidebar.name}
                  button
                  onClick={() => sidebar.action === "uploadPost" ? handleUploadPostOpen() : navigate(sidebar.link)}
                  selected={location.pathname === sidebar.link}
                  sx={{
                    marginBottom: "4px",
                    padding: '5px 8px',
                    borderRadius: '8px',
                    color: "rgba(255, 29, 29, 1)",
                    '&:hover': {
                      backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    },
                    '&.Mui-selected, &.Mui-selected:hover': {
                      backgroundColor: 'rgba(255, 29, 29, 1)',
                    },
                  }}
                >
                  <ListItemIcon>{sidebar.logo}</ListItemIcon>
                  <ListItemText
                    primary={sidebar.name}
                    sx={{
                      color: location.pathname === sidebar.link ? 'white' : 'black',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>

        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              marginTop: '15px',
            },
          }}
        >
          <Toolbar />
          <Box className="h-full px-3 pb-4 font-Montserat bg-primarBG overflow-y-auto">
            <List>
              {sidebarProps?.map((sidebar) => (
                <div key={sidebar?.id}>
                  <ListItem
                    key={sidebar?.name}
                    button
                    onClick={() => sidebar.action === "uploadPost" ? handleUploadPostOpen() : navigate(sidebar.link)}
                    selected={location.pathname === sidebar.link}
                    sx={{
                      marginBottom: "4px",
                      padding: '5px 8px',
                      fontFamily: 'Sacramento',
                      borderRadius: '8px',
                      color: "rgba(255, 29, 29, 1)",
                      '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                      },
                      '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: 'rgba(255, 29, 29, 1)',
                      },
                    }}
                  >
                    <ListItemIcon>{sidebar?.logo} </ListItemIcon>
                    <ListItemText
                      primary={sidebar?.name}
                      sx={{
                        color: location.pathname === sidebar?.link ? 'white' : 'black',
                      }}
                    />
                  </ListItem>
                </div>
              ))}
              <UploadPost open={isUploadDialogOpen} onClose={handleUploadPostClose} />
            </List>
          </Box>
        </Drawer>

        <Box
          className='bg-primarBG'
          component="main"
          sx={{
            flexGrow: 1,
            p: 4,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: { lg: drawerOpen ? `${drawerWidth}px` : 60 },
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Sidebar;
