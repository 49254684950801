import React, { createContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { publicRoute, userRoutes } from "src/routes";
import siteConstant from "./helpers/constant/siteConstant";
import { fetchFromStorage, saveToStorage } from "./helpers/context/storage";

import messagesEn from 'src/helpers/locales/en.json';

const Context = createContext();


const App = () => {
  const localAuth = fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA)
  // const viewRoutes = userRoutes;
  const viewRoutes = localAuth ? userRoutes : publicRoute;

  const routing = useRoutes(viewRoutes);



  const menuMessages = {
    en: { ...messagesEn }
  };

  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState(menuMessages['en']);
  const [socialIcons, setSocialIcons] = useState(siteConstant?.SOCIAL_ICONS)
  const [channels, setChanels] = useState(siteConstant?.CHANNEL_LIST)
  const [playerId, setPlayerId] = useState('');

  const switchLanguage = lang => {
    saveToStorage(siteConstant?.INDENTIFIERS.acceptlanguage, lang);
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };

  useEffect(() => {
    var defaultLang =
      fetchFromStorage(siteConstant?.INDENTIFIERS.acceptlanguage) !== null
        ? fetchFromStorage(siteConstant?.INDENTIFIERS.acceptlanguage)
        : 'en';
    setLocale(defaultLang);
    setMessages(menuMessages[defaultLang]);
    saveToStorage(siteConstant?.INDENTIFIERS.acceptlanguage, defaultLang);
    // eslint-disable-next-line
  }, [locale, fetchFromStorage(siteConstant?.INDENTIFIERS.acceptlanguage)]);


  return (
    <HelmetProvider>
      <React.Suspense fallback="">
        <Context.Provider value={{ locale, switchLanguage, messages, socialIcons, channels, userConnectedChannel: localAuth }}>
          {routing}
        </Context.Provider>
      </React.Suspense>
    </HelmetProvider>

  );
};

export default App;
export { Context as IntlContext };
