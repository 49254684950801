import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

export const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "500",
      fontSize: "15px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: (props) => props.borderRadius || '8px',
      "& .MuiOutlinedInput-input": {
        padding: "17px 15px",
        fontFamily: 'Montserrat, sans-serif',
        fontSize: "15px",
        fontWeight: "500",
        "&::placeholder": {
          color: "#252520",
          fontSize: "14px",
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: "500",
        }
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: (props) => props.borderColor || "1px solid #D4D6D8",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: (props) => props.borderColor || "#4582c3",
        borderWidth: "1px",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: (props) => props.borderColor || "#4582c3",
      },

      "& .Mui-disabled": {
        cursor: "not-allowed",
      },
    },
    "& .MuiInputLabel-root": {
      fontFamily: 'Montserrat, sans-serif',
      color: "black",
      fontSize: '14px',
      "&.Mui-focused": {
        color: "black",
      },
      "&.Mui-error": {
        color: "#f44336",
      },
    },
    "& .MuiInputBase-input:focus": {
      boxShadow: "none",
    },
  },
})(TextField);
